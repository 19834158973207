export default {
  firstName: null,
  lastName: null,
  traits: [],
  abilities: [],
  username: null,
  role: null,
  id: null,
  authLink: null,
};
