export default {
  getFullName: (state) => `${state.firstName} ${state.lastName}`,
  getFirstName: (state) => state.firstName,
  getLastName: (state) => state.lastName,
  getEmail: (state) => state.email,
  getRole: (state) => state.role,
  getUsername: (state) => state.username,
  getId: (state) => state.id,
  getAbilities: (state) => state.abilities,
  userIsSet: (state) => (!!state.id),
  getCountryCodes: (state) => state.countryCodes,
  getAuthLink: (state) => state.authLink,
};
