import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from '@axios';
// import { get } from 'jquery';
import store from '@/store';

import ability from '../libs/acl/ability'

Vue.use(VueRouter);

async function getLoginLink() {
  await axios.get('auth/loginLink').then((res) => {
    store.dispatch('user/setLink', res.data);
  });
}

async function handleLogin(to, next) {
  let unauthorized = true;
  await axios
    .get('user')
    .then((res) => {
      store.dispatch('user/setUser', res.data).then(() => {
        ability.update(store.state.user.abilities)
      });
      unauthorized = false;
    })
    .catch(async (err) => {
      if (err.response.status === 401) {
        await getLoginLink();
      }
    });
  if (unauthorized && to.path !== '/login') {
    next({ path: '/login' });
  } else {
    if (to.path === '/login' && unauthorized === false) {
      next({ path: '/' });
    }
    else{
      next();
    }
  }
}

const router = new VueRouter({
  mode: 'history',
  base: 'admin/v2',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      redirect: '/companies',
    },
    {
      path: '/companies',
      name: 'companies',
      component: () => import('@/views/company/CompanyList.vue'),
      meta: {
        pageTitle: 'Firmy',
        breadcrumb: [
          {
            text: 'Firmy',
            active: true,
          },
        ],
      },
    },
    {
      path: '/companies/:id',
      name: 'company',
      component: () => import('@/views/company/CompanyDetail.vue'),
      meta: {
        pageTitle: 'Detail firmy',
        breadcrumb: [
          {
            text: 'Detail firmy',
            active: true,
          },
        ],
      },
    },
    {
      path: '/companyusers',
      name: 'company users',
      component: () => import('@/views/companyUser/CompanyUserList.vue'),
      meta: {
        pageTitle: 'Firemní uživatelé',
        breadcrumb: [
          {
            text: 'Firemní uživatelé',
            active: true,
          },
        ],
      },
    },
    {
      path: '/companyusers/:id',
      name: 'company user detail',
      component: () => import('@/views/companyUser/CompanyUserDetail.vue'),
      meta: {
        pageTitle: 'Detail uživatele',
        breadcrumb: [
          {
            text: 'Firemní uživatele',
            to: '/companyusers',
          },
          {
            text: 'Detail uživatele',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('@/views/invoice/InvoiceList.vue'),
      meta: {
        pageTitle: 'Faktury',
        breadcrumb: [
          {
            text: 'Faktury',
            active: true,
          },
        ],
      },
    },
    {
      path: '/invoices/:id',
      name: 'invoice detail',
      component: () => import('@/views/invoice/InvoiceDetail.vue'),
      meta: {
        pageTitle: 'Detail faktury',
        breadcrumb: [
          {
            text: 'Faktury',
            to: '/invoices',
          },
          {
            text: 'Detail faktury',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/user/UserList.vue'),
      meta: {
        pageTitle: 'Operátoři',
        breadcrumb: [
          {
            text: 'Operátoři',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payments',
      name: 'payments',
      component: () => import('@/views/payment/PaymentList.vue'),
      meta: {
        pageTitle: 'Výplaty',
        breadcrumb: [
          {
            text: 'Výplaty',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payment/:id',
      name: 'payment detail',
      component: () => import('@/views/payment/PaymentDetail.vue'),
      meta: {
        pageTitle: 'Detail výplaty',
        breadcrumb: [
          {
            text: 'Výplaty',
            to: '/payments',
          },
          {
            text: 'Detail výplaty',
            active: true,
          },
        ],
      },
    },
    {
      path: '/offer/:offerId/',
      name: 'offer detail',
      component: () => import('@/views/offers/OfferDetail.vue'),
      meta: {
        pageTitle: 'Detail nabídky',
        breadcrumb: [
          {
            text: 'Firmy',
            to: '/companies',
          },
          {
            text: 'Detail nabídky',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/add',
      name: 'add user',
      component: () => import('@/views/user/UserDetail.vue'),
      meta: {
        pageTitle: 'Přidat operátora',
        breadcrumb: [
          {
            text: 'Operátoři',
            to: '/users',
          },
          {
            text: 'Přidat operátora',
            active: true,
          },
        ],
        isNew: true,
      },
    },
    {
      path: '/users/:id',
      name: 'user',
      component: () => import('@/views/user/UserDetail.vue'),
      meta: {
        pageTitle: 'Detail operátora',
        breadcrumb: [
          {
            text: 'Operátoři',
            to: '/users',
          },
          {
            text: 'Detail operátora',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reports/purchases',
      name: 'purchase report',
      component: () => import('@/views/reports/PurchaseReport.vue'),
      meta: {
        pageTitle: 'Report - Nákupy',
      },
    },
    {
      path: '/reports/productbonus',
      name: 'product bonus report',
      component: () => import('@/views/reports/ProductBonus.vue'),
      meta: {
        pageTitle: 'Report bonusů - Produkty',
      },
    },
    {
      path: '/reports/companybonus',
      name: 'company bonus report',
      component: () => import('@/views/reports/CompanyBonus.vue'),
      meta: {
        pageTitle: 'Report bonusů - Zákazníci',
      },
    },
    {
      path: '/reports/companies',
      name: 'company report',
      component: () => import('@/views/reports/CompanyReport.vue'),
      meta: {
        pageTitle: 'Report - Firmy',
      },
    },
    {
      path: '/reports/payments',
      name: 'payments report',
      component: () => import('@/views/reports/PaymentsReport.vue'),
      meta: {
        pageTitle: 'Report - Výplaty',
      },
    },
    {
      path: '/reports/companyusers',
      name: 'company users report',
      component: () => import('@/views/reports/CompanyUsersReport.vue'),
      meta: {
        pageTitle: 'Report - Uživatelé',
      },
    },
    {
      path: '/reports/operators',
      name: 'operators report',
      component: () => import('@/views/reports/OperatorsReport.vue'),
      meta: {
        pageTitle: 'Report - Operátoři',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isAuth = store.getters['user/userIsSet'];
  if (isAuth === false) {
    handleLogin(to, next);
  } else {

    next();
  }
});

export default router;
