export default {
  setUser({ commit }, res) {
    commit('SET_USER', res);
  },
  setLink({ commit }, res) {
    commit('SET_USER', { authLink: res });
  },
  logoutUser({ commit }) {
    commit('LOGOUT_USER');
  },
};
