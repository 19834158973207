import Vue from 'vue';

// // axios
import axios from 'axios';

const axiosIns = axios.create({
  globalErrorHandling: true,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: process.env.VUE_APP_API_URL,
});

axiosIns.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.config.globalErrorHandling) {
      if (error.response.status === 401) {
      // TODO: is this condition needed? Response status 401 is handled in router/index.js
      } else {
        const serverErrorCodes = [500, 502, 503, 504];
        const forbiddenErrorCodes = [403];
        if (serverErrorCodes.includes(error.response.status)) {
          Vue.swal({
            icon: 'error',
            title: 'Nastala neočekávaná chyba',
            text: 'V aplikaci se vyskytla neočekávaná chyba. Vaše akce nemusí být uložena.',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        }
        if (forbiddenErrorCodes.includes(error.response.status)) {
          Vue.swal({
            icon: 'error',
            title: 'Nastala chyba',
            text: 'Nepovolená akce',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        }
      }
    }
    return Promise.reject(error);
  },
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
