import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

import moduleUser from './user/moduleUser';
import moduleConfig from './config/moduleConfig';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user: moduleUser,
    config: moduleConfig,
  },
  strict: process.env.DEV,
});
