import axios from 'axios';

export default {
  setConfig({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/admin/config/')
        .then((res) => {
          commit('SET_CONFIG', res.data);
          resolve();
        }).catch((err) => {
          reject(err.response.data);
        });
    });
  },
};
