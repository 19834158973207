export default {
  SET_USER(state, user) {
    const abilities = user.role === 'admin'
      ? [{ action: 'header', subject: 'Nav' }]
      : [{ action: 'menuTitle', subject: 'Nav' }];

    if(user.traits){
      abilities.push(
        ...user.traits.map((trait) => ({
          action: trait,
          subject: 'User',
        })),
      )
    }

    state.firstName = user.firstName;
    state.lastName = user.lastName;
    state.username = user.username;
    state.traits = user.traits;
    state.abilities = abilities;
    state.role = user.role;
    // eslint-disable-next-line no-underscore-dangle
    state.id = user.id;
    state.authLink = user.authLink;
  },
  LOGOUT_USER(state) {
    state.firstName = null;
    state.lastName = null;
    state.email = null;
    state.username = null;
    state.sysAdmin = false;
    state.traits = [];
    state.abilities = [];
    state.role = null;
    state.id = null;
    state.countryCodes = [];
    state.authLink = null;
  },

};
