/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import Vue from 'vue';
import { ToastPlugin, ModalPlugin, VBTooltip } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import i18n from '@/libs/i18n';

// import CKEditor from '@ckeditor/ckeditor5-vue2'
import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/toastification';
import '@/libs/acl';
import '@/libs/sweet-alerts';
import '@/libs/loading';
// import './registerServiceWorker'

// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-tooltip', VBTooltip);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Vue.use(CKEditor)

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
